import React, {useEffect, useState} from 'react';
import {getSingleProduct} from '../../store/actions/products';
import {useDispatch, useSelector} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {addToCart} from '../../store/actions/cart';
import {RootState} from '../../store';
import {useFormik} from 'formik';
import {FormValues} from '../../interfaces';
import {validate} from '../../utils/validate';
import {sendSepaPaymentIntent} from '../../store/actions/subscriptions';
import {useNavigate} from 'react-router-dom';
import styles from '../products/styles/product.module.scss';
import lnf from './solidarite.module.scss';

export function MonthsSolidarite() {

  const dispatch = useDispatch<ThunkDispatch<string, unknown, AnyAction>>();
  const slug = 'un-enfant-pour-un-annee';

  const basket = useSelector((state: any) => state.products);
  const {product} = basket;

  const [monthly, setMonthly] = useState(false);
  const [iban, setIbanPayment] = useState();

  const cart: any = useSelector((state: RootState) => state.cart);
  const {products} = cart;
  const itemInCart: any = products?.find((x: any) => x.id === product?.id);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleProduct(slug as string)).then(() => {
    });
  }, [dispatch, slug]);

  const toggleMonthly = () => {
    setMonthly(!monthly);
  };


  const sendToChildProductToCart = (price_cents: string) => {
    let finalAmount: number;
    finalAmount = 100 * parseInt(price_cents);

    const finalData: any = {
      id: '901',
      title: 'Un enfant pour un anneé',
      photo:
          'https://res.cloudinary.com/kazaeat/image/upload/v1707354937/enfant.jpg',
      prix_cents: finalAmount,
    };

    dispatch(
        // @ts-ignore
        addToCart(finalData, 1),
    ).then(r => console.log(r));
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      prenom: '',
      nom: '',
      societe: '',
      addresse: '',
      ville: '',
      code_postal: '',
      pays: 'France',
      client: 'frontend',
      stripe_product_id: product?.stripe_product_id || '',
      stripe_price_id: product?.stripe_price_id || '',
      iban: '',
      charge_date: '',
      amount: product?.amount || '',
    },
    validate,
    onSubmit: (values) => {
      console.log(iban);
      localStorage.setItem('iban', values.iban);
      console.log(`-----Values ${JSON.stringify(values)}`);

      if (!values.email || !values.prenom || !values.nom) {
        console.log('Missing details -----------');
      } else {
        dispatch(sendSepaPaymentIntent(values) as any).then(() => {
          navigate('/sepa');
        });
      }
    },
  });

  const ibanPayment = (data: any) => {
    console.log(JSON.stringify(data));

    setIbanPayment((prevData) => {
      return data;
    });

    formik.handleSubmit();
  };

  return (
      <div className="container">
        <div className="row justify-content-md-center text-center">
          <div className="col-md-10">
            <div className={lnf.sellOption}>
              <span>1 Enfant <i className="fa-solid fa-arrow-right"></i> 12 MOIS</span>
              <div className={lnf.sellOptionAmount}>1 800€</div>
              <hr/>
              <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-5">
                  <div
                      onClick={() =>
                          sendToChildProductToCart(
                              product.price_cents)
                      }
                      className={`btn btn-secondary btn-sm mt-1 position-relative`}
                  >
                    <i className="fa-duotone fa-credit-card me-1"></i>
                    &nbsp; Paiement unique &nbsp;
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div
                      onClick={toggleMonthly}
                      className="btn btn-warning btn-sm mt-1 mb-1">
                    <i className="fa-duotone fa-repeat me-2"></i>
                    Paiement en plusieurs fois
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {monthly && (
            <div className={lnf.chosenOptionMonthlyBg}>
              <div className="row">
                <div className="col-md-12">
                  {monthly && !itemInCart && (
                      <>
                        <div
                            className={`ps-3 pe-3 mt-2 mb-2 ${styles.donationPaymentDetails}`}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-12 mb-3">
                              <label htmlFor="email">Votre e-mail
                                *</label>
                              <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                              />
                              {formik.errors.email ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.email}
                                  </div>
                              ) : null}
                            </div>

                            <div className="col-sm-12 col-md-6 mb-sm-3">
                              <label htmlFor="prenom">Prénom *</label>
                              <input
                                  id="prenom"
                                  name="prenom"
                                  type="name"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.prenom}
                              />
                              {formik.touched.prenom &&
                              formik.errors.prenom ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.prenom}
                                  </div>
                              ) : null}
                            </div>

                            <div className="col-sm-12 col-md-6 mb-sm-3">
                              <label htmlFor="nom">Nom *</label>
                              <input
                                  id="nom"
                                  name="nom"
                                  type="name"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.nom}
                              />
                              {formik.touched.nom && formik.errors.nom
                                  ? (
                                      <div
                                          className="invalid-feedback">
                                        {formik.errors.nom}
                                      </div>
                                  )
                                  : null}
                            </div>

                            <div className="col-12 mb-3">
                              <label htmlFor="email">Adresse *</label>
                              <input
                                  id="addresse"
                                  name="addresse"
                                  type="text"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.addresse}
                              />
                              {formik.touched.addresse &&
                              formik.errors.addresse ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.addresse}
                                  </div>
                              ) : null}
                            </div>

                            <div className="col-sm-12 col-md-4 mb-3">
                              <label htmlFor="ville">Ville *</label>
                              <input
                                  id="ville"
                                  name="ville"
                                  type="text"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.ville}
                              />
                              {formik.touched.ville &&
                              formik.errors.ville ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.ville}
                                  </div>
                              ) : null}
                            </div>
                            <div className="col-sm-12 col-md-4 mb-3">
                              <label htmlFor="code_postal">Code Postal
                                *</label>
                              <input
                                  id="code_postal"
                                  name="code_postal"
                                  type="text"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.code_postal}
                              />
                              {formik.touched.code_postal &&
                              formik.errors.code_postal
                                  ? (
                                      <div className="text-danger">
                                        {formik.errors.code_postal}
                                      </div>
                                  )
                                  : null}
                            </div>

                            <div className="col-sm-12 col-md-4 mb-3">
                              <label htmlFor="pays">Pays *</label>
                              <input
                                  id="pays"
                                  name="pays"
                                  type="text"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.pays}
                              />
                              {formik.touched.pays &&
                              formik.errors.pays ? (
                                  <div
                                      className="text-danger">{formik.errors.pays}</div>
                              ) : null}
                            </div>

                            <hr/>
                          </div>

                          <div className="row">

                            <div className="col-sm-12 col-md-8 mb-3">
                              <label htmlFor="email">IBAN *</label>
                              <input
                                  id="iban"
                                  name="iban"
                                  type="text"
                                  className="form-control form-control-sm"
                                  onChange={formik.handleChange}
                                  value={formik.values.iban}
                              />
                              {formik.errors.iban ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.iban}
                                  </div>
                              ) : null}
                            </div>


                            {product &&
                                product.donation_period ===
                                'monthly' && (

                                    <div className="col-sm-12 col-md-4 mb-3">
                                      <label htmlFor="charge_date">Date de
                                        Prélèvement *</label>
                                      <select
                                          id="charge_date"
                                          name="charge_date"
                                          className="form-control form-control-md"
                                          onChange={formik.handleChange}
                                          value={formik.values.charge_date}
                                      >
                                        {Array.from({length: 31},
                                            (_, index) => index + 1).map((day) => (
                                                <option key={day} value={day}>
                                                  {day}
                                                </option>
                                            ))}
                                      </select>
                                      {formik.errors.charge_date ? (
                                          <div className="invalid-feedback">
                                            {formik.errors.charge_date}
                                          </div>
                                      ) : null}
                                    </div>

                                )}
                          </div>
                        </div>
                        <div
                            className={`text-center  ${styles.donationPaymentSelection}`}
                        >
                          <div className="row justify-content-md-center">
                            <div className="col-sm-12 col-md-10">
                              <div
                                  onClick={() => ibanPayment('IBAN')}
                                  className="btn btn-success btn-lg"
                              >
                                <i className="fa-duotone fa-building-columns me-2"></i>
                                Confirmer le virement bancaire
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                  )}
                </div>
              </div>
            </div>
        )}
      </div>
  );

}