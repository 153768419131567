import {v4 as uuidv4} from 'uuid';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {SET_ALERT, REMOVE_ALERT} from '../constants/alertConstants';

export const setAlert = (msg: string, alertType: string, timeout = 5000) => async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {
    const id = uuidv4();

    dispatch({
        type: SET_ALERT,
        payload: {msg, alertType, id}
    });

    setTimeout(() => dispatch({
        type: REMOVE_ALERT,
        payload: id
    }), timeout);
};