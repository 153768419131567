import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";

import { alertReducer } from "./reducers/alertReducer";
import { productReducer } from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducer";
import { layoutReducer } from "./reducers/layout";
import { paymentReducer } from "./reducers/paymentReducer";
import { userReducer } from "./reducers/userReducer";
import { subscriptionReducer } from "./reducers/subscriptionReducer";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["alert", "product"],
};

const rootReducer = combineReducers({
  alert: alertReducer,
  products: productReducer,
  cart: cartReducer,
  layoutReducer: layoutReducer,
  payment: paymentReducer,
  sepa_subscription: subscriptionReducer,
  userReducer,
});

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const middlewares = [ReduxThunk, logger];
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
