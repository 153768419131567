import {
  GET_ALL_BASKETS_FAIL,
  GET_ALL_PRODUCTS,
  GET_SINGLE_PRODUCT,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_BASKETS,
  GET_ALL_BASKETS_REQUEST,
  GET_ALL_PRODUCTS_REQUEST,
  GET_SINGLE_PRODUCT_REQUEST,
} from "../constants/productConstants";

const initialState = {
  products: [],
  baskets: [],
  product: {},
  pending: true,
};

export const productReducer = (
  state = initialState,
  action: { type: any; payload: { data: any } }
) => {
  switch (action.type) {
    case GET_SINGLE_PRODUCT_REQUEST:
    case GET_ALL_PRODUCTS_REQUEST:
    case GET_ALL_BASKETS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        pending: false,
      };
    case GET_SINGLE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        pending: false,
      };
    case GET_ALL_BASKETS:
      return {
        ...state,
        baskets: action.payload,
        pending: false,
      };
    case GET_ALL_BASKETS_FAIL:
    case GET_ALL_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
