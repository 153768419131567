import lnf from './solidarite.module.scss';
import React, {useState} from 'react';
import {addToCart} from '../../store/actions/cart';
import {useDispatch, useSelector} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {RootState} from '../../store';
import {useNavigate} from 'react-router-dom';

const product: any = {
  id: '902',
  title: 'Un enfant pour un anneé',
  photo: 'https://res.cloudinary.com/kazaeat/image/upload/v1707354937/enfant.jpg',
  prix_cents: 15000,
};

export function SelectChild() {
  const dispatch = useDispatch<ThunkDispatch<string, unknown, AnyAction>>();
  const [quantity, setQuantity] = useState(1);

  const [individuals, setIndividuals] = useState(false);

  const cart: any = useSelector((state: RootState) => state.cart);
  const {products} = cart;
  const itemInCart: any = products?.find((x: any) => x.id === product?.id);

  const navigate = useNavigate();

  const handleAddToCart = () => {
    dispatch(addToCart(product, quantity) as any);
  };

  const goToCheckout = () => {
    navigate('/confirm');
  };

  return (
      <div className="container text-center">
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            <div className={lnf.sellOption}
                 onClick={() => setIndividuals(!individuals)}>
              <span>1 Enfant <i className="fa-solid fa-arrow-right"></i> 30 JOURS</span>
              <div className={lnf.sellOptionAmount}>150€
                {itemInCart && (
                    <span className="ms-1 badge rounded-pill text-bg-success">
                  x{itemInCart?.qty}
                </span>
                )}
              </div>
              <hr/>
              <div className="row justify-content-md-center">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-start">
                        <select
                            className="form-select"
                            value={quantity}
                            onChange={(e) => setQuantity(
                                parseInt(e.target.value))}
                        >
                          {[...Array(12)].map((_, index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1} enfants
                              </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 mb-2">
                      <button
                          onClick={handleAddToCart}
                          className="btn btn-warning btn-sm mt-1 position-relative"
                      >
                        Choisir
                      </button>
                    </div>
                    {itemInCart && (
                        <div
                            onClick={() => goToCheckout()}
                            className="btn btn-warning btn-sm mt-3 mb-3">
                          <i className="fa-duotone fa-credit-card me-1"></i>
                          Procéder au paiement</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
