import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import api from "../../utils/api";
import {
  GET_ALL_PRODUCTS,
  GET_ALL_BASKETS,
  GET_SINGLE_PRODUCT,
  GET_ALL_BASKETS_REQUEST,
  GET_ALL_PRODUCTS_REQUEST,
  GET_SINGLE_PRODUCT_REQUEST,
} from "../constants/productConstants";
import { setAlert } from "./alert";

export const getAllProducts =
  () => async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {
    try {
      dispatch({
        type: GET_ALL_PRODUCTS_REQUEST,
      });

      const res = await api.get("/produits.json");

      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: res.data,
      });
    } catch (error: any) {
      const errorResponse = error.response.data;

      await dispatch(
        setAlert(
          error.response && error.response.data.message
            ? error.response.data.message
            : errorResponse.error,
          "danger"
        )
      );
    }
  };

export const getAllBaskets =
  () => async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {
    try {
      dispatch({
        type: GET_ALL_BASKETS_REQUEST,
      });

      const res = await api.get("/baskets.json");

      dispatch({
        type: GET_ALL_BASKETS,
        payload: res.data,
      });
    } catch (error: any) {
      const errorResponse = error.response.data;

      await dispatch(
        setAlert(
          error.response && error.response.data.message
            ? error.response.data.message
            : errorResponse.error,
          "danger"
        )
      );
    }
  };

export const getSingleProduct =
  (id: string) =>
  async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {
    try {
      dispatch({
        type: GET_SINGLE_PRODUCT_REQUEST,
      });

      const res = await api.get(`/baskets/${id}.json`);

      dispatch({
        type: GET_SINGLE_PRODUCT,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(
        `Error -------- ${error.response && error.response.data.message}`
      );
    }
  };
