import {
  SEND_USER_DETAILS,
  RESET_USER_DETAILS,
  LOADING_OR_PENDING,
} from "../constants/userConstants";

const initialState = {
  token: null,
  loading: false,
};

export const userReducer = (
  state = initialState,
  action: { type: any; payload: { data: any } }
) => {
  switch (action.type) {
    case LOADING_OR_PENDING:
      return {
        loading: true,
      };
    case SEND_USER_DETAILS:
      // @ts-ignore
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload,
        loading: false,
      };
    case RESET_USER_DETAILS:
      localStorage.removeItem("token");
      return {
        token: {},
      };
    default:
      return state;
  }
};
