import { SEPA_PAYMENT_INTENT } from "../constants/subscriptionConstant";

const initialState = {
  clientSecret: "",
  stripeUserId: "",
};
export const subscriptionReducer = (
  state = initialState,
  action: {
    type: any;
    payload: {
      clientSecret: any;
      stripeUserId: any;
      data: string;
    };
  }
) => {
  switch (action.type) {
    case SEPA_PAYMENT_INTENT:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        stripeUserId: action.payload.stripeUserId,
      };
    default:
      return state;
  }
};
