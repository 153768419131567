import React from 'react';
import SolidariteTeaser from './teaser';

const Solidarite = () => {

  return (
      <div className="row justify-content-md-center">
        <SolidariteTeaser />
      </div>
  );
};

export default Solidarite;