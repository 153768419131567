import logo from "./images/logo_blue.svg";

export function Loading() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <img src={logo} height="150" className="loading-logo" alt="Yeshiva"/>
                </div>
            </div>
        </div>
    );
}