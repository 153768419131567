import {
    USER_MODAL_ON,
    USER_MODAL_OFF
} from '../constants/layoutConstants';

const initialState = {
    modalStatus: false,
};


export const layoutReducer = (state = initialState, action: { type: any; payload: { data: any; }; }) => {

    switch (action.type) {

        case USER_MODAL_ON:
            return {
                modalStatus: true,
            };

        case USER_MODAL_OFF:
            return {
                modalStatus: false,
            };

        default:
            return state;
    }
};