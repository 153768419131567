import {ADD_TO_CART, CART_REMOVE_ITEM} from "../constants/cartConstants";
import {ProductType} from "../../utils/interfaces";
import {RESET_CART_DETAILS} from "../constants/paymentConstants";

const cartDefaultState = {
    products: [],
    totalPrice: "0",
    itemsPrice: "0",
};

export const cartReducer = (
    state = cartDefaultState,
    action: { type: any; payload: ProductType }
) => {
    switch (action.type) {
        case ADD_TO_CART:
            const product = action.payload;

            let item: any = state.products?.find(
                (x: ProductType) => x.id === product.id
            );

            if (item) {
                item.qty++;
                return {
                    ...state,
                    products: state.products.map((x) => (x === item ? item : x)),
                };
            } else {
                return {
                    ...state,
                    products: [...state.products, product],
                };
            }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                products: state.products.filter((x: any) => x.id !== action.payload),
            };
        case RESET_CART_DETAILS:
            localStorage.removeItem("token");
            return cartDefaultState;
        default:
            return state;
    }
};
