import css from './teaser.module.scss';
import React from 'react';
import {Link} from 'react-router-dom';

const SolidariteTeaser = () => {

    return (
        <div className={css.charityTeaserWrapper}>
            <div
                className="row">

                <div className="col-sm-1 col-md-7">

                </div>
                <div className="col-md-5 pt-md-5">
                    <div className={css.charityTeaserWords}>
                        <h1 className="animate__animated animate__fadeIn animate__delay-1s">Venez en aide à cet enfant ! </h1>
                        <Link to={`/solidarite/information`}
                              className="btn btn-warning btn-enfant  btn-lg mb-4">
                            Voir les options
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SolidariteTeaser;