import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../../store';
// import {hideUserModal} from "../../store/actions/layout";
import css from '../donations/css/holiday.module.scss';
import {addToCart} from '../../store/actions/cart';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';

const product: any = {
  id: "900",
  title: "Pessah 5784-2024",
  photo:
    "https://res.cloudinary.com/kazaeat/image/upload/v1711028532/pessah_2024.jpg",
  prix_cents: 65000,
};

const HolidayPackage = () => {
  const dispatch = useDispatch<ThunkDispatch<string, unknown, AnyAction>>();

  const cart: any = useSelector((state: RootState) => state.cart);
  const { products } = cart;

  const itemInCart: any = products?.find((x: any) => x.id === product?.id);
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/confirm");
  };

  return (
    <div className={`${css.Donate} row justify-content-md-center`}>
      <div className="col-sm-12 col-md-6 text-center">
        <div className={`${css.DonateInner} d-flex justify-content-evenly`}>
          1 famille &nbsp; <b>650 €</b>
          {itemInCart && (
            <>
              &nbsp; X
              <div className={`${css.productInCartNumber}`}>
                {
                  // @ts-ignore
                  itemInCart?.qty
                }
              </div>
            </>
          )}
          <div className="text-end">

            <div
                onClick={() =>
                    dispatch(addToCart(product, 1) as any)
                }
                className={`btn btn-warning btn-sm mt-1 position-relative}`}
            >
              &nbsp; Offrez ce package &nbsp;
              {itemInCart && (
                  <span
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      <>x{itemInCart?.qty}</>
                      <span className="visually-hidden">vans votre panier</span>
                    </span>
              )}
            </div>


          </div>
        </div>

        {itemInCart && (
            <div onClick={redirect} className="btn btn-warning btn-lg mt-3">
              <i className="bi bi-lock me-1"></i>
              Aller à la caisse et payer
            </div>
        )}
      </div>
    </div>
  );
};

export default HolidayPackage;
