import { REMOVE_ALERT, SET_ALERT } from '../constants/alertConstants';
import { AlertType } from "../../utils/interfaces";
import { AnyAction } from "redux";

const initialState: AlertType[] = [];

export const alertReducer = (state = initialState, action: AnyAction) => {

    const {type, payload} = action;

    switch (type) {
        case SET_ALERT:
            return [...state, payload];
        case REMOVE_ALERT:
            return state.filter((alert) => alert.id !== payload);
        default:
            return state;
    }
}