import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/es/integration/react';

import Routers from '../src/app/Routes';
import './app.scss';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './store/index';

const persist = persistStore(store);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);


root.render(
    <Provider store={store}>
      <PersistGate loading={"...."} persistor={persist}>
        <Routers />
      </PersistGate>
    </Provider>
);

reportWebVitals();