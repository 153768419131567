import css from './charity.module.scss';
import React, {useEffect} from 'react';
import styles from '../products/styles/product.module.scss';
import sponsorWords from '../products/images/sponsor.jpg';
import {MonthsSolidarite} from '../solidarite/months';
import {SelectChild} from '../solidarite/singleChild';
import {LibreChild} from '../solidarite/libre';

const SolidariteDetails = () => {

  useEffect(() => {
    document.body.classList.add("solidarite-body");
    return () => {
      document.body.classList.remove("solidarite-body");
    };
  }, []);

  return (
      <>
        <div className={`${css.charityWrapper} row justify-content-md-center`}>
          <div className="col-md-7">
            <img
                src="https://res.cloudinary.com/kazaeat/image/upload/v1711909989/enfant.jpg"
                className="img-fluid" alt="solidarite"/>
          </div>
          <div className="col-md-5">
            <div className={styles.productChildSide}>
              <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-9">
                  <div
                      className={styles.productChildTitle}>
                    <img src={sponsorWords}
                         alt="bg"
                         className="img-fluid"/>
                  </div>
                </div>
              </div>
              <hr/>

              {/*Child item start */}
              <div
                  className="row justify-content-md-center mt-3">
                <div className="col-md-5">
                  <div
                      className={styles.productSubChildTitle}>
                    <h5>Pour 1 jour</h5>
                  </div>
                </div>
              </div>
              <div
                  className="row justify-content-md-center mt-3 text-center">
                <div className="col-3 col-md-2">
                  <h3 className={styles.productSubChildItem}>1</h3>
                  <div
                      className={styles.productSubChildItemDescription}>Enfant
                  </div>
                </div>
                <div className="col-2 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-solid fa-arrow-right"></i>
                  </h6>
                </div>
                <div className="col-2 col-md-2">
                  <h3 className={styles.productSubChildItem}>1</h3>
                  <div
                      className={styles.productSubChildItemDescription}>Jour
                  </div>
                </div>
                <div className="col-1 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-solid fa-equals"></i>
                  </h6>
                </div>
                <div className="col-3 col-md-2 pt-2">
                  <h3 className={styles.productSubChildItemEqual}>5€</h3>
                </div>
              </div>
              <hr/>
              {/*Child item end */}

              {/*Child item start */}
              <div
                  className="row justify-content-md-center mt-3">
                <div className="col-md-5">
                  <div
                      className={styles.productSubChildTitle}>
                    <h5>Pour 1 mois</h5>
                  </div>
                </div>
              </div>
              <div
                  className="row justify-content-md-center mt-3 text-center">
                <div className="col-3 col-md-2">
                  <h3 className={styles.productSubChildItem}>1</h3>
                  <div
                      className={styles.productSubChildItemDescription}>Enfant
                  </div>
                </div>
                <div className="col-2 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-solid fa-arrow-right"></i>
                  </h6>
                </div>
                <div className="col-2 col-md-2">
                  <h3 className={styles.productSubChildItem}>30</h3>
                  <div
                      className={styles.productSubChildItemDescription}>Jours
                  </div>
                </div>
                <div className="col-1 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-solid fa-equals"></i>
                  </h6>
                </div>
                <div className="col-3 col-md-2">
                  <h3 className={styles.productSubChildItemEqual}>150€</h3>
                  <div
                      className={styles.productSubChildItemDescription}>1
                    Mois
                  </div>
                </div>
              </div>
              <hr/>
              {/*Child item end */}


              {/*Child item start */}
              <div
                  className="row justify-content-md-center mt-3">
                <div className="col-md-5">
                  <div
                      className={styles.productSubChildTitle}>
                    <h5>Pour 1 année</h5>
                  </div>
                </div>
              </div>
              <div
                  className="row justify-content-md-center mt-3 text-center">
                <div className="col-3 col-md-2">
                  <h3 className={styles.productSubChildItem}>150€</h3>
                </div>
                <div className="col-1 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-sharp fa-solid fa-xmark"></i>
                  </h6>
                </div>
                <div className="col-2 col-md-2">
                  <h3 className={styles.productSubChildItem}>12</h3>
                  <div
                      className={styles.productSubChildItemDescription}>Mois
                  </div>
                </div>
                <div className="col-1 col-md-1">
                  <h6 className={styles.productSubChildMaths}>
                    <i className="fa-solid fa-equals"></i>
                  </h6>
                </div>
                <div className="col-3 col-md-2">
                  <h3 className={styles.productSubChildItemEqual}>1800€</h3>
                  <div
                      className={styles.productSubChildItemDescription}>1
                    Année
                  </div>
                </div>
              </div>
              <hr/>
              {/*Child item end */}

            </div>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            <MonthsSolidarite/>
          </div>
          <div className="col-md-3">
            <SelectChild/>
          </div>
          <div className="col-md-3">
            <LibreChild/>
          </div>
        </div>
      </>
  );
};

export default SolidariteDetails;