import {isValidIBAN} from 'ibantools';

export const validate = (values: any) => {
  const errors: any = {};
  if (!values.prenom) {
    errors.prenom = "Obligatoire";
  } else if (values.prenom.length > 15) {
    errors.prenom = "Doit contenir 15 caractères ou moins";
  }

  if (!values.nom) {
    errors.nom = "Obligatoire";
  } else if (values.nom.length > 20) {
    errors.nom = "Doit contenir 20 caractères ou moins";
  }

  if (!values.email) {
    errors.email = "Obligatoire";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Adresse e-mail invalide";
  }

  if (!values.iban) {
    errors.iban = "Obligatoire";
  } else if (!isValidIBAN(values.iban)) {
    errors.iban = "L'IBAN n'est pas valide. Aucun espace ni caractère spécial n'est autorisé.";
  }

  return errors;
};
