import {
    CONFIRM_CART_DETAILS,
    DELETE_PAYMENT_INTENT,
} from "../constants/paymentConstants";

interface SecretType {
    clientSecret: string;
}

const initialState: SecretType = {
    clientSecret: "",
};

export const paymentReducer = (
    state = initialState,
    action: { type: any; payload: { data: string } }
) => {
    switch (action.type) {
        case CONFIRM_CART_DETAILS:
            return {
                ...state,
                clientSecret: action.payload,
            };
        case DELETE_PAYMENT_INTENT:
            return {
                clientSecret: ""
            };
        default:
            return state;
    }
};
