import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {ADD_TO_CART, CART_REMOVE_ITEM} from '../constants/cartConstants';
import {ProductType} from '../../utils/interfaces';
import {RESET_CART_DETAILS} from '../constants/paymentConstants';
import {RESET_USER_DETAILS} from '../constants/userConstants';

export const addToCart =
    (product: ProductType, qty: number) =>
        async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {

          const prod = {
            id: product.id,
            title: product.title,
            photo: product.photo,
            prix_cents: product.prix_cents,
            qty,
          };
          try {
            console.log('Added to cart');

            dispatch({
              type: ADD_TO_CART,
              payload: prod,
            });
          } catch (error: any) {
            console.log(`------${error}--Error ${JSON.stringify(error)}`);
          }
        };

export const removeFromCart = (id: number) => (dispatch: any) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });
};

export const clearCart = () => (dispatch: any) => {
  console.log(`----Got here clicked.......`);
  dispatch({
    type: RESET_CART_DETAILS,
  });

  dispatch({
    type: RESET_USER_DETAILS,
  });
};
