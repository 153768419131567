import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import api from '../../utils/api';
import {setAlert} from './alert';
import {SEPA_PAYMENT_INTENT} from '../constants/subscriptionConstant';

export const sendSepaPaymentIntent = (user: any) => {
  return async (dispatch: ThunkDispatch<string, unknown, AnyAction>) => {
    try {
      const res = await api.post(
          "https://www.layeshivaducoeur.com/donations/front_sepa_intention",
          { user }
      );

      dispatch({
        type: SEPA_PAYMENT_INTENT,
        payload: res.data,
      });

      console.log(res);
    } catch (error: any) {
      const errorResponse = error.response.data;

      await dispatch(
          setAlert(
              error.response && error.response.data.message
                  ? error.response.data.message
                  : errorResponse.error,
              "danger"
          )
      );
    }
  };
};
