import lnf from './solidarite.module.scss';
import React, {useState} from 'react';
import {addToCart} from '../../store/actions/cart';
import {useDispatch} from 'react-redux';

export function LibreChild() {
  const dispatch = useDispatch();

  const [libre, setLibre] = useState(false);
  const [custom, setCustom] = useState('');

  const onChange = (event: any) => {
    setCustom(event.target.value);
  };

  const onSubmitCustom = (custom: any) => {
    sendToCard(custom);
  };

  const sendToCard = (prix_cents: string) => {
    let finalAmount: number;
    finalAmount = 100 * parseInt(prix_cents);

    setCustom(prix_cents);

    const finalData: any = {
      id: '900',
      title: 'Don personnalisé',
      photo:
          'https://res.cloudinary.com/lofdwwlay/image/upload/v1656578117/logo_red_j4s8cp.jpg',
      prix_cents: finalAmount,
    };

    dispatch(
        // @ts-ignore
        addToCart(finalData, 1),
    );
  };

  return (
      <div className="container ">
        <div className="row justify-content-md-center text-center">
          <div className="col-md-12">
            <div className={lnf.sellOptionLibre}
                 onClick={() => setLibre(!libre)}>
              DON LIBRE
            </div>
          </div>
        </div>
        {libre && (
            <div className={lnf.chosenOptionBg}>
              <div className="row justify-content-md-center">
                <div className="col-md-12">
                  <div className="row justify-content-md-center">

                    <div className="col-md-12">
                      <label htmlFor="email">Montant Libre *</label>
                      <input value={custom} id={'amount'} onChange={onChange}
                             placeholder={'5.00'}/>
                    </div>
                    <div className="col-md-12">
                      <div
                          className="btn btn-success btn-lg ms-2 mt-3"
                          onClick={() => {
                            onSubmitCustom(custom);
                          }}
                      >
                        <i className="fa-duotone fa-credit-card me-1"></i>
                        Procéder au paiement
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
      </div>
  );
}